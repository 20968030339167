$max-viewport-nb: 1920;
$max-viewport-mb: 768;
@function get-vw-nb($target) {
    $vw-context: ($max-viewport-nb * .01) * 1px;
    @return ($target/$vw-context) * 1vw;
}
@function get-vw-mb($target) {
    $vw-context: ($max-viewport-mb * .01) * 1px;
    @return ($target/$vw-context) * 1vw;
}

body {
    font-family: "Noto Sans Thai", sans-serif;
    font-size: 28px;
    color: #fff;
    line-height: 1.4;
    background-color: #262626;
}
.header {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 80px;
    padding: 0 40px;
    border-bottom: 3px solid #404040;
    background-color: #262626;
}
.header-logo {
    display: flex;
    align-items: center;
}
.header-logo span {
    font-family: "Bai Jamjuree", sans-serif;
    font-size: 30px;
    color: #bdbdbd;
    font-weight: 700;
}
.header-toggle {
    position: relative;
    display: none;
    justify-content: center;
    align-items: center;
    width: 96px;
    height: 100%;
    padding-left: 2px;
    z-index: 101;
}
.btn-nav-toggle {
    position: relative;
    width: 43px;
    height: 30px;
}
.btn-nav-toggle span {
    position: absolute;
    display: block;
    height: 4px;
    width: 43px;
    background: #bdbdbd;
    border-radius: 50px;
    transition: all 0s 0.2s;
}
.btn-nav-toggle.active span {
    background: #fff;
}
.btn-nav-toggle span:nth-child(1) {
    top: 0;
    left: 0;
    transition: 0.2s 0.2s, opacity 0s 0.2s;
}
.btn-nav-toggle span:nth-child(2) {
    top: 13px;
    left: 0;
    width: 32px;
    transition: 0.2s 0s;
}
.btn-nav-toggle span:nth-child(3) {
    top: 13px;
    left: 0;
    width: 32px;
    transition: 0.2s 0s;
}
.btn-nav-toggle span:nth-child(4) {
    top: 26px;
    left: 0;
    transition: 0.2s 0.2s, opacity 0s 0.2s;
}
.btn-nav-toggle.active span:nth-child(1) {
    top: 13px;
    opacity: 0;
    transition: 0.2s 0s, opacity 0s 0.2s;
}
.btn-nav-toggle.active span:nth-child(2) {
    width: 43px;
    transform: rotate(-45deg);
    transition: 0.2s 0.2s;
}
.btn-nav-toggle.active span:nth-child(3) {
    width: 43px;
    transform: rotate(45deg);
    transition: 0.2s 0.2s;
}
.btn-nav-toggle.active span:nth-child(4) {
    top: 13px;
    opacity: 0;
    transition: 0.2s 0s, opacity 0s 0.2s;
}
.header-nav {
    display: flex;
    justify-content: center;
}
.nav-list {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 100px;
}
.nav-list li {
    position: relative;
    font-family: "Bai Jamjuree", sans-serif;
    font-size: 20px;
    color: #bdbdbd;
    line-height: 1.4;
    font-weight: 500;
    padding: 15px 0;
}
.nav-list li:before {
    content: "";
    position: absolute;
    top: 50%;
    left: -50px;
    width: 2px;
    height: 26px;
    background-color: #404040;
    transform: translateY(-50%);
}
.nav-list li a {
    color: #bdbdbd;
    text-underline-offset: 6px;
    transition: color .25s ease, opacity .25s ease, text-decoration .25s ease;
}
.nav-list li a.active {
    color: #3d83f2;
    text-decoration: underline;
}
.nav-list li a:hover {
    opacity: 0.7;
    text-decoration: underline;
}
.main {
    height: 100dvh;
    padding-top: 80px;
}
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 40px;
}
.site-content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}
.col-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 770px;
    height: 100%;
}
.col-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 1070px;
    height: 100%;
}
.space-left {
    padding-left: 100px;
    padding-right: 80px;
}
.space-right {
    display: flex;
    justify-content: flex-end;
    padding-right: 100px;
}
h1 {
    font-size: 38px;
    color: #fff;
    font-weight: 600;
}
h1 span {
    font-family: "Bai Jamjuree", sans-serif;
    font-size: 48px;
    color: #3d83f2;
    font-weight: 700;
}
.upload-file {
    position: relative;
    display: flex;
    margin-top: 20px;
}
.upload-file input[type=file] {
	position: absolute;
	top: 0;
	left: 0;
    width: 100%;
    height: 100%;
	margin: 0;
	padding: 0;
	cursor: pointer;
	opacity: 0;
    z-index: 9;
}
.upload-file-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 180px;
    padding: 12px 20px;
    border-radius: 8px;
    border: 2px dashed #bdbdbd;
    // background-color: #444;
    background-color: transparent;
    box-shadow: inset 0 2px 2px rgba(0, 0, 0, .1);
    transition: background-color .25s ease, border-color .25s ease;
}
.upload-file-btn.active {
    border-color: #3c82f4;
}
.upload-file input[type=file]:hover + .upload-file-btn {
    border-color: #fff;
}
.upload-file-icon {
    font-size: 36px;
    color: #bdbdbd;
    line-height: 1;
    transition: color .25s ease;
}
.upload-file-detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}
.upload-file-label {
    font-size: 16px;
    color: #bdbdbd;
    line-height: 1.6;
    text-align: center;
    transition: color .25s ease;
}
.upload-file-btn.active .upload-file-icon, 
.upload-file-btn.active .upload-file-label {
    color: #3c82f4;
}
.upload-file input[type=file]:hover + .upload-file-btn .upload-file-icon, 
.upload-file input[type=file]:hover + .upload-file-btn .upload-file-label {
    color: #fff;
}
.upload-file-label strong {
    font-weight: 700;
}
.submit-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 49px;
    font-size: 20px;
    color: #fff;
    font-weight: 600;
    gap: 15px;
    margin-top: 20px;
    padding: 18px 20px;
    overflow: hidden;
    border-radius: 8px;
    background-color: #3c82f4;
    transition: color .25s ease, background-color .25s ease;
}
.submit-btn.disable, 
.submit-btn.loading {
    color: #888;
    pointer-events: none;
    background-color: #444;
}
.submit-btn:hover {
    background-color: #0444ab;
}
.upload-desc {
    margin-top: 45px;
}
.upload-desc p {
    font-size: 16px;
    color: #bebebe;
    margin-bottom: 10px;
}
.upload-desc ul {
    list-style: disc;
    margin-top: 10px;
    padding-left: 50px;
}
.upload-desc li {
    list-style: disc;
    font-size: 16px;
    color: #bebebe;
}
.code-content {
    position: relative;
    width: 100%;
}
.syntax-highlighter {
    width: 100%;
    height: 65vh;
    font-size: 16px !important;
    border-radius: 22px !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: hidden !important;
    border: 2px solid #404040;
    background-color: #0a0a0a !important;
}
.syntax-highlighter code {
    display: block;
    width: 100%;
    height: 100%;
    font-size: 16px !important;
    user-select: all;
    padding: 20px 20px 20px 6px;
    overflow: auto;
}
.react-syntax-highlighter-line-number {
    min-width: 70px !important;
    color: #666 !important;
    padding-right: 22px !important;
}
.syntax-highlighter code::-webkit-scrollbar {
    width: 8px;
    height: 0;
    background: rgba(255,255,255,0.1);
    transition: all .25s ease;
}
.syntax-highlighter code::-webkit-scrollbar-track {
    background: transparent;
}
.syntax-highlighter code::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 60px;
}
.syntax-highlighter code::-webkit-scrollbar:hover {
    background: rgba(255,255,255,0.2);
}
.copy-btn {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    color: #bebebe;
    overflow: hidden;
    padding: 8px 24px 8px 16px;
    border-radius: 0 24px 0 8px;
    background-color: #404040;
    transition: color .25s ease;
}
.copy-btn:hover {
    color: #fff;
}
.content-detail {
    width: 100%;
    height: 65vh;
    border-radius: 22px;
    overflow: hidden;
    user-select: all;
    border: 2px solid #404040;
    background-color: #0a0a0a;
}
.content-wrapper {
    width: 100%;
    height: 100%;
    font-size: 16px;
    text-align: left;
    padding: 30px;
    overflow: auto;
}
.content-wrapper::-webkit-scrollbar {
    width: 8px;
    height: 0;
    background: rgba(255,255,255,0.1);
    transition: all .25s ease;
}
.content-wrapper::-webkit-scrollbar-track {
    background: transparent;
}
.content-wrapper::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 60px;
}
.content-wrapper::-webkit-scrollbar:hover {
    background: rgba(255,255,255,0.2);
}
.content-detail h1 {
    font-size: 160%;
}
.content-detail h2 {
    font-size: 145%;
}
.content-detail h3 {
    font-size: 130%;
}
.content-detail h4 {
    font-size: 120%;
}
.content-detail h5 {
    font-size: 110%;
}
.content-detail h6 {
    font-size: 100%;
}
.content-detail p {
    font-size: 100%;
}
.content-detail h1, 
.content-detail h2,
.content-detail h3,
.content-detail h4,
.content-detail h5,
.content-detail h6,
.content-detail p, 
.content-detail img {
    text-align: left;
    margin-bottom: 20px;
}
.content-detail a {
    color: #629bf8;
    text-decoration: underline;
    text-underline-offset: 4px;
    transition: color .25s ease;
}
.content-detail a:hover {
    color: #8cb5f8;
}
.content-detail img {
    width: 100%;
    height: auto;
    pointer-events: none;
}
.input-css {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 12px;
    overflow: hidden;
    border: 2px solid #4b5563;
    background-color: #1F2937;
}
.input-css textarea {
    min-width: 100%;
    max-width: 100%;
    min-height: 200px;
    max-height: 300px;
    font-size: 14px;
    color: #fff;
    line-height: 1.5;
    padding: 12px 15px;
}
.input-css textarea::-webkit-scrollbar {
    width: 8px;
    height: 0;
    background: rgba(255,255,255,0.1);
    transition: all .25s ease;
}
.input-css textarea::-webkit-scrollbar-track {
    background: transparent;
}
.input-css textarea::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 60px;
}
.input-css textarea::-webkit-scrollbar:hover {
    background: rgba(255,255,255,0.2);
}
.tab-nav {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 30px;
    gap: 20px;
}
.tab-nav-item {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex: 1;
    font-size: 18px;
    color: #888;
    line-height: 1.4;
    padding: 10px 20px;
    gap: 10px;
    border-radius: 8px;
    background-color: #444;
    transition: color .25s ease, background-color .25s ease;
}
.tab-nav-item:hover {
    color: #fff;
    background-color: #0444ab;
}
.tab-nav-item.active {
    color: #fff;
    background-color: #3c82f4;
}
.tab-nav-item p {
    font-size: 18px;
    color: #888;
    font-weight: 500;
    transition: color .25s ease;
}
.tab-nav-item:hover p {
    color: #fff;
}
.tab-nav-item.active p {
    color: #fff;
    font-weight: 700;
}
.tab-content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-top: 20px;
}
.col-right .tab-content {
    margin-top: 0;
}
.tab-content-item {
    display: none;
    width: 100%;
}
.tab-content-item.active {
    display: block;
}
@media (max-width: 1919px) {
    body {
        font-size: get-vw-nb(28px);
    }
    .header {
        height: get-vw-nb(80px);
        padding: 0 get-vw-nb(40px);
        border-width: get-vw-nb(3px);
    }
    .header-logo span {
        font-size: get-vw-nb(30px);
    }
    .header-toggle {
        width: get-vw-nb(96px);
        padding-left: get-vw-nb(2px);
    }
    .btn-nav-toggle {
        width: get-vw-nb(43px);
        height: get-vw-nb(30px);
    }
    .btn-nav-toggle span {
        height: get-vw-nb(4px);
        width: get-vw-nb(43px);
        border-radius: get-vw-nb(50px);
    }
    .btn-nav-toggle span:nth-child(2) {
        top: get-vw-nb(13px);
        width: get-vw-nb(32px);
    }
    .btn-nav-toggle span:nth-child(3) {
        top: get-vw-nb(13px);
        width: get-vw-nb(32px);
    }
    .btn-nav-toggle span:nth-child(4) {
        top: get-vw-nb(26px);
    }
    .btn-nav-toggle.active span:nth-child(1) {
        top: get-vw-nb(13px);
    }
    .btn-nav-toggle.active span:nth-child(2) {
        width: get-vw-nb(43px);
    }
    .btn-nav-toggle.active span:nth-child(3) {
        width: get-vw-nb(43px);
    }
    .btn-nav-toggle.active span:nth-child(4) {
        top: get-vw-nb(13px);
    }    
    .nav-list {
        gap: get-vw-nb(100px);
    }
    .nav-list li {
        font-size: get-vw-nb(20px);
        padding: get-vw-nb(15px) 0;
    }
    .nav-list li:before {
        left: get-vw-nb(-50px);
        width: get-vw-nb(2px);
        height: get-vw-nb(26px);
    }
    .nav-list li a {
        text-underline-offset: get-vw-nb(6px);
    }
    .main {
        padding-top: get-vw-nb(80px);
    }
    .container {
        padding: 0 get-vw-nb(40px);
    }
    .col-left {
        width: get-vw-nb(770px);
    }
    .col-right {
        width: get-vw-nb(1070px);
    }
    .space-left {
        padding-left: get-vw-nb(100px);
        padding-right: get-vw-nb(80px);
    }
    .space-right {
        padding-right: get-vw-nb(100px);
    }
    h1 {
        font-size: get-vw-nb(38px);
    }
    h1 span {
        font-size: get-vw-nb(48px);
    }
    .upload-file {
        margin-top: get-vw-nb(20px);
    }
    .upload-file-btn {
        height: get-vw-nb(180px);
        padding: get-vw-nb(12px) get-vw-nb(20px);
        border-radius: get-vw-nb(8px);
        border-width: get-vw-nb(2px);
        box-shadow: inset 0 get-vw-nb(2px) get-vw-nb(2px) rgba(0, 0, 0, .1);
    }
    .upload-file-icon {
        font-size: get-vw-nb(36px);
    }
    .upload-file-detail {
        margin-top: get-vw-nb(10px);
    }
    .upload-file-label {
        font-size: get-vw-nb(16px);
    }
    .submit-btn {
        min-height: get-vw-nb(49px);
        font-size: get-vw-nb(20px);
        gap: get-vw-nb(15px);
        margin-top: get-vw-nb(20px);
        padding: get-vw-nb(18px) get-vw-nb(20px);
        border-radius: get-vw-nb(8px);
    }
    .upload-desc {
        margin-top: get-vw-nb(45px);
    }
    .upload-desc p {
        font-size: get-vw-nb(16px);
        margin-bottom: get-vw-nb(10px);
    }
    .upload-desc ul {
        margin-top: get-vw-nb(10px);
        padding-left: get-vw-nb(50px);
    }
    .upload-desc li {
        font-size: get-vw-nb(16px);
    }
    .syntax-highlighter {
        font-size: get-vw-nb(16px) !important;
        border-radius: get-vw-nb(22px) !important;
        border: get-vw-nb(2px) solid #404040;
    }
    .syntax-highlighter code {
        font-size: get-vw-nb(16px) !important;
        padding: get-vw-nb(20px) get-vw-nb(20px) get-vw-nb(20px) get-vw-nb(6px);
    }
    .react-syntax-highlighter-line-number {
        min-width: get-vw-nb(70px) !important;
        padding-right: get-vw-nb(22px) !important;
    }
    .syntax-highlighter code::-webkit-scrollbar {
        width: get-vw-nb(8px);
    }
    .syntax-highlighter code::-webkit-scrollbar-thumb {
        border-radius: get-vw-nb(60px);
    }
    .copy-btn {
        gap: get-vw-nb(8px);
        font-size: get-vw-nb(16px);
        padding: get-vw-nb(8px) get-vw-nb(24px) get-vw-nb(8px) get-vw-nb(16px);
        border-radius: 0 get-vw-nb(24px) 0 get-vw-nb(8px);
    }
    .content-detail {
        border-radius: get-vw-nb(22px);
        border-width: get-vw-nb(2px);
    }
    .content-wrapper {
        font-size: get-vw-nb(16px);
        padding: get-vw-nb(30px);
    }
    .content-wrapper::-webkit-scrollbar {
        width: get-vw-nb(8px);
    }
    .content-wrapper::-webkit-scrollbar-thumb {
        border-radius: get-vw-nb(60px);
    }
    .content-detail h1, 
    .content-detail h2,
    .content-detail h3,
    .content-detail h4,
    .content-detail h5,
    .content-detail h6,
    .content-detail p, 
    .content-detail img {
        margin-bottom: get-vw-nb(20px);
    }
    .content-detail a {
        text-underline-offset: get-vw-nb(4px);
    }
    .input-css {
        border-radius: get-vw-nb(12px);
        border: get-vw-nb(2px) solid #4b5563;
    }
    .input-css textarea {
        min-height: get-vw-nb(200px);
        max-height: get-vw-nb(300px);
        font-size: get-vw-nb(14px);
        padding: get-vw-nb(12px) get-vw-nb(15px);
    }
    .input-css textarea::-webkit-scrollbar {
        width: get-vw-nb(8px);
    }
    .input-css textarea::-webkit-scrollbar-thumb {
        border-radius: get-vw-nb(60px);
    }
    .tab-nav {
        margin-top: get-vw-nb(30px);
        gap: get-vw-nb(20px);
    }
    .tab-nav-item {
        font-size: get-vw-nb(18px);
        padding: get-vw-nb(10px) get-vw-nb(20px);
        gap: get-vw-nb(10px);
        border-radius: get-vw-nb(8px);
    }
    .tab-nav-item p {
        font-size: get-vw-nb(18px);
    }
    .tab-content {
        margin-top: get-vw-nb(20px);
    }
}
@media (max-width: 768px) {
    body {
        font-size: get-vw-mb(28px);
    }
    .header {
        height: auto;
        min-height: get-vw-mb(100px);
        padding: 0 get-vw-mb(10px) 0 get-vw-mb(30px);
        border-width: get-vw-mb(3px);
        z-index: 99;
    }
    .header-logo {
        position: relative;
        z-index: 100;
    }
    .header-logo span {
        font-size: get-vw-mb(34px);
    }
    .header-toggle {
        display: flex;
        width: get-vw-mb(96px);
        padding-left: get-vw-mb(2px);
        z-index: 100;
    }
    .btn-nav-toggle {
        width: get-vw-mb(43px);
        height: get-vw-mb(30px);
    }
    .btn-nav-toggle span {
        height: get-vw-mb(4px);
        width: get-vw-mb(43px);
        border-radius: get-vw-mb(50px);
    }
    .btn-nav-toggle span:nth-child(2) {
        top: get-vw-mb(13px);
        width: get-vw-mb(32px);
    }
    .btn-nav-toggle span:nth-child(3) {
        top: get-vw-mb(13px);
        width: get-vw-mb(32px);
    }
    .btn-nav-toggle span:nth-child(4) {
        top: get-vw-mb(26px);
    }
    .btn-nav-toggle.active span:nth-child(1) {
        top: get-vw-mb(13px);
    }
    .btn-nav-toggle.active span:nth-child(2) {
        width: get-vw-mb(43px);
    }
    .btn-nav-toggle.active span:nth-child(3) {
        width: get-vw-mb(43px);
    }
    .btn-nav-toggle.active span:nth-child(4) {
        top: get-vw-mb(13px);
    }    
    .header-nav {
        position: fixed;
        top: 0;
        left: 100%;
        width: 100%;
        height: 100dvh;
        z-index: 1;
        opacity: 0;
        visibility: hidden;
        z-index: 99;
        background-color: #000;
        transition: all .6s ease;
    }
    .header-nav.active {
        left: 0;
        opacity: 1;
        visibility: visible;
    }
    .nav-list {
        flex-direction: column;
        gap: get-vw-mb(100px);
    }
    .nav-list li {
        font-size: get-vw-mb(34px);
        text-align: center;
        padding: 0;
    }
    .nav-list li:before {
        display: none;
    }
    .nav-list li a {
        text-underline-offset: get-vw-mb(6px);
    }
    .nav-list li a:hover {
        opacity: 1;
        text-decoration: none;
    }
    .nav-list li a:active {
        opacity: 0.7;
        text-decoration: underline;
    }
    .main {
        height: 100dvh;
        padding-top: get-vw-mb(100px);
    }
    .container {
        flex-direction: column;
        height: auto;
        padding: get-vw-mb(25px) get-vw-mb(30px) get-vw-mb(40px);
    }
    .site-content {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .col-left, 
    .col-right {
        width: 100%;
        height: auto;
    }
    .space-left {
        padding-left: 0;
        padding-right: 0;
    }
    .space-right {
        justify-content: flex-start;
        padding-right: 0;
    }
    h1 {
        font-size: get-vw-mb(54px);
        text-align: center;
    }
    h1 span {
        font-size: get-vw-mb(65px);
    }
    .upload-file {
        margin-top: get-vw-mb(50px);
    }
    .upload-file-btn {
        height: get-vw-mb(300px);
        padding: get-vw-mb(30px);
        border-radius: get-vw-mb(10px);
        border-width: get-vw-mb(2px);
        box-shadow: inset 0 get-vw-mb(2px) get-vw-mb(2px) rgba(0, 0, 0, .1);
    }
    .upload-file input[type=file]:hover + .upload-file-btn {
        border-color: #bdbdbd;
    }
    .upload-file-icon {
        font-size: get-vw-mb(68px);
        color: #bdbdbd;
    }
    .upload-file-detail {
        margin-top: get-vw-mb(20px);
    }
    .upload-file-label {
        font-size: get-vw-mb(26px);
    }
    .upload-file input[type=file]:hover + .upload-file-btn .upload-file-icon, 
    .upload-file input[type=file]:hover + .upload-file-btn .upload-file-label {
        color: #bdbdbd;
    }
    .upload-file input[type=file]:active + .upload-file-btn .upload-file-icon, 
    .upload-file input[type=file]:active + .upload-file-btn .upload-file-label {
        color: #fff;
    }
    .submit-btn {
        min-height: get-vw-mb(60px);
        font-size: get-vw-mb(30px);
        gap: get-vw-mb(15px);
        margin-top: get-vw-mb(25px);
        padding: get-vw-mb(25px) get-vw-mb(30px);
        border-radius: get-vw-mb(8px);
    }
    .submit-btn:hover {
        background-color: #3c82f4;
    }
    .submit-btn:active {
        background-color: #0444ab;
    }
    .upload-desc {
        margin-top: get-vw-mb(35px);
    }
    .upload-desc p {
        font-size: get-vw-mb(20px);
        margin-bottom: get-vw-mb(10px);
    }
    .upload-desc ul {
        columns: 2;
        margin-top: get-vw-mb(10px);
        padding-left: get-vw-mb(50px);
    }
    .upload-desc li {
        font-size: get-vw-mb(20px);
    }
    .code-content {
        margin-top: get-vw-mb(50px);
    }
    .syntax-highlighter {
        height: 76vw;
        font-size: get-vw-mb(16px) !important;
        border-radius: get-vw-mb(22px) !important;
        border-width: get-vw-mb(4px);
    }
    .syntax-highlighter code {
        font-size: get-vw-mb(16px) !important;
        padding: get-vw-mb(20px) get-vw-mb(20px) get-vw-mb(20px) get-vw-mb(6px);
    }
    .react-syntax-highlighter-line-number {
        min-width: get-vw-mb(70px) !important;
        padding-right: get-vw-mb(22px) !important;
    }
    .syntax-highlighter code::-webkit-scrollbar {
        width: get-vw-mb(10px);
    }
    .syntax-highlighter code::-webkit-scrollbar-thumb {
        border-radius: get-vw-mb(60px);
    }
    .syntax-highlighter code::-webkit-scrollbar:hover {
        background: rgba(255,255,255,0.1);
    }
    .syntax-highlighter code::-webkit-scrollbar:active {
        background: rgba(255,255,255,0.2);
    }
    .copy-btn {
        gap: get-vw-mb(8px);
        font-size: get-vw-mb(22px);
        padding: get-vw-mb(12px) get-vw-mb(28px) get-vw-mb(12px) get-vw-mb(20px);
        border-radius: 0 get-vw-mb(24px) 0 get-vw-mb(10px);
    }
    .copy-btn:hover {
        color: #bebebe;
    }
    .copy-btn:active {
        color: #fff;
    }
    .content-detail {
        height: 80vw;
        border-radius: get-vw-mb(22px);
        border-width: get-vw-mb(4px);
    }
    .content-wrapper {
        font-size: get-vw-mb(16px);
        padding: get-vw-mb(30px);
    }
    .content-wrapper::-webkit-scrollbar {
        width: get-vw-mb(10px);
    }
    .content-wrapper::-webkit-scrollbar-thumb {
        border-radius: get-vw-mb(60px);
    }
    .content-wrapper::-webkit-scrollbar:hover {
        background: rgba(255,255,255,0.1);
    }
    .content-wrapper::-webkit-scrollbar:active {
        background: rgba(255,255,255,0.2);
    }
    .content-detail h1, 
    .content-detail h2,
    .content-detail h3,
    .content-detail h4,
    .content-detail h5,
    .content-detail h6,
    .content-detail p, 
    .content-detail img {
        margin-bottom: get-vw-mb(20px);
    }
    .content-detail a {
        text-underline-offset: get-vw-mb(4px);
    }
    .content-detail a:hover {
        color: #629bf8;
    }
    .content-detail a:active {
        color: #8cb5f8;
    }
    .input-css {
        border-radius: get-vw-mb(12px);
        border-width: get-vw-mb(2px);
    }
    .input-css textarea {
        min-height: get-vw-mb(250px);
        max-height: get-vw-mb(500px);
        font-size: get-vw-mb(18px);
        padding: get-vw-mb(12px) get-vw-mb(15px);
    }
    .input-css textarea::-webkit-scrollbar {
        width: get-vw-mb(10px);
    }
    .input-css textarea::-webkit-scrollbar-thumb {
        border-radius: get-vw-mb(60px);
    }
    .input-css textarea::-webkit-scrollbar:hover {
        background: rgba(255,255,255,0.1);
    }
    .input-css textarea::-webkit-scrollbar:active {
        background: rgba(255,255,255,0.2);
    }
    .tab-nav {
        margin-top: get-vw-mb(40px);
        gap: get-vw-mb(20px);
    }
    .tab-nav-item {
        font-size: get-vw-mb(24px);
        padding: get-vw-mb(14px) get-vw-mb(20px);
        gap: get-vw-mb(10px);
        border-radius: get-vw-mb(8px);
    }
    .tab-nav-item:hover {
        color: #888;
        background-color: #444;
    }
    .tab-nav-item.active {
        color: #fff;
        background-color: #3c82f4;
    }
    .tab-nav-item p {
        font-size: get-vw-mb(24px);
    }
    .tab-nav-item:hover p {
        color: #888;
    }
    .tab-nav-item.active p {
        color: #fff;
    }
    .tab-content {
        margin-top: get-vw-mb(25px);
    }
}