@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&family=Noto+Sans+Thai:wght@100..900&display=swap');
/*-------------------------------
Reset
-------------------------------*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    list-style: none;
    text-transform: none;
    vertical-align: baseline;
}
button, input, textarea, select, a {
    padding: 0;
    margin: 0;
    outline: 0;
    border: 0;
    background-color: transparent;
    -webkit-appearance: none;
    appearance: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
    display: block;
}
button, a {
    cursor: pointer;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
*, *:before, *:after {
    outline: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
html, body { 
    position: relative;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}
body { 
    line-height: 1.6;
    font-weight: normal;
    -webkit-font-smoothing: antialiased; 
    -moz-osx-font-smoothing: grayscale; 
    -webkit-overflow-scrolling: touch;
}
a, a:active, a:focus { 
    outline: 0;
    text-decoration: none;
    -webkit-box-shadow: none; 
    box-shadow: none; 
}
a:focus, a:hover { 
    text-decoration: none;
}
a[href^="tel"] {
    color: inherit;
    text-decoration: none;
}