body {
  font-family: "Noto Sans Thai", sans-serif;
  font-size: 28px;
  color: #fff;
  line-height: 1.4;
  background-color: #262626;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  padding: 0 40px;
  border-bottom: 3px solid #404040;
  background-color: #262626;
}

.header-logo {
  display: flex;
  align-items: center;
}

.header-logo span {
  font-family: "Bai Jamjuree", sans-serif;
  font-size: 30px;
  color: #bdbdbd;
  font-weight: 700;
}

.header-toggle {
  position: relative;
  display: none;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 100%;
  padding-left: 2px;
  z-index: 101;
}

.btn-nav-toggle {
  position: relative;
  width: 43px;
  height: 30px;
}

.btn-nav-toggle span {
  position: absolute;
  display: block;
  height: 4px;
  width: 43px;
  background: #bdbdbd;
  border-radius: 50px;
  transition: all 0s 0.2s;
}

.btn-nav-toggle.active span {
  background: #fff;
}

.btn-nav-toggle span:nth-child(1) {
  top: 0;
  left: 0;
  transition: 0.2s 0.2s, opacity 0s 0.2s;
}

.btn-nav-toggle span:nth-child(2) {
  top: 13px;
  left: 0;
  width: 32px;
  transition: 0.2s 0s;
}

.btn-nav-toggle span:nth-child(3) {
  top: 13px;
  left: 0;
  width: 32px;
  transition: 0.2s 0s;
}

.btn-nav-toggle span:nth-child(4) {
  top: 26px;
  left: 0;
  transition: 0.2s 0.2s, opacity 0s 0.2s;
}

.btn-nav-toggle.active span:nth-child(1) {
  top: 13px;
  opacity: 0;
  transition: 0.2s 0s, opacity 0s 0.2s;
}

.btn-nav-toggle.active span:nth-child(2) {
  width: 43px;
  transform: rotate(-45deg);
  transition: 0.2s 0.2s;
}

.btn-nav-toggle.active span:nth-child(3) {
  width: 43px;
  transform: rotate(45deg);
  transition: 0.2s 0.2s;
}

.btn-nav-toggle.active span:nth-child(4) {
  top: 13px;
  opacity: 0;
  transition: 0.2s 0s, opacity 0s 0.2s;
}

.header-nav {
  display: flex;
  justify-content: center;
}

.nav-list {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
}

.nav-list li {
  position: relative;
  font-family: "Bai Jamjuree", sans-serif;
  font-size: 20px;
  color: #bdbdbd;
  line-height: 1.4;
  font-weight: 500;
  padding: 15px 0;
}

.nav-list li:before {
  content: "";
  position: absolute;
  top: 50%;
  left: -50px;
  width: 2px;
  height: 26px;
  background-color: #404040;
  transform: translateY(-50%);
}

.nav-list li a {
  color: #bdbdbd;
  text-underline-offset: 6px;
  transition: color 0.25s ease, opacity 0.25s ease, text-decoration 0.25s ease;
}

.nav-list li a.active {
  color: #3d83f2;
  text-decoration: underline;
}

.nav-list li a:hover {
  opacity: 0.7;
  text-decoration: underline;
}

.main {
  height: 100dvh;
  padding-top: 80px;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 40px;
}

.site-content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.col-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 770px;
  height: 100%;
}

.col-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 1070px;
  height: 100%;
}

.space-left {
  padding-left: 100px;
  padding-right: 80px;
}

.space-right {
  display: flex;
  justify-content: flex-end;
  padding-right: 100px;
}

h1 {
  font-size: 38px;
  color: #fff;
  font-weight: 600;
}

h1 span {
  font-family: "Bai Jamjuree", sans-serif;
  font-size: 48px;
  color: #3d83f2;
  font-weight: 700;
}

.upload-file {
  position: relative;
  display: flex;
  margin-top: 20px;
}

.upload-file input[type=file] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  cursor: pointer;
  opacity: 0;
  z-index: 9;
}

.upload-file-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 180px;
  padding: 12px 20px;
  border-radius: 8px;
  border: 2px dashed #bdbdbd;
  background-color: transparent;
  box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.1);
  transition: background-color 0.25s ease, border-color 0.25s ease;
}

.upload-file-btn.active {
  border-color: #3c82f4;
}

.upload-file input[type=file]:hover + .upload-file-btn {
  border-color: #fff;
}

.upload-file-icon {
  font-size: 36px;
  color: #bdbdbd;
  line-height: 1;
  transition: color 0.25s ease;
}

.upload-file-detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.upload-file-label {
  font-size: 16px;
  color: #bdbdbd;
  line-height: 1.6;
  text-align: center;
  transition: color 0.25s ease;
}

.upload-file-btn.active .upload-file-icon,
.upload-file-btn.active .upload-file-label {
  color: #3c82f4;
}

.upload-file input[type=file]:hover + .upload-file-btn .upload-file-icon,
.upload-file input[type=file]:hover + .upload-file-btn .upload-file-label {
  color: #fff;
}

.upload-file-label strong {
  font-weight: 700;
}

.submit-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 49px;
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  gap: 15px;
  margin-top: 20px;
  padding: 18px 20px;
  overflow: hidden;
  border-radius: 8px;
  background-color: #3c82f4;
  transition: color 0.25s ease, background-color 0.25s ease;
}

.submit-btn.disable,
.submit-btn.loading {
  color: #888;
  pointer-events: none;
  background-color: #444;
}

.submit-btn:hover {
  background-color: #0444ab;
}

.upload-desc {
  margin-top: 45px;
}

.upload-desc p {
  font-size: 16px;
  color: #bebebe;
  margin-bottom: 10px;
}

.upload-desc ul {
  list-style: disc;
  margin-top: 10px;
  padding-left: 50px;
}

.upload-desc li {
  list-style: disc;
  font-size: 16px;
  color: #bebebe;
}

.code-content {
  position: relative;
  width: 100%;
}

.syntax-highlighter {
  width: 100%;
  height: 65vh;
  font-size: 16px !important;
  border-radius: 22px !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden !important;
  border: 2px solid #404040;
  background-color: #0a0a0a !important;
}

.syntax-highlighter code {
  display: block;
  width: 100%;
  height: 100%;
  font-size: 16px !important;
  user-select: all;
  padding: 20px 20px 20px 6px;
  overflow: auto;
}

.react-syntax-highlighter-line-number {
  min-width: 70px !important;
  color: #666 !important;
  padding-right: 22px !important;
}

.syntax-highlighter code::-webkit-scrollbar {
  width: 8px;
  height: 0;
  background: rgba(255, 255, 255, 0.1);
  transition: all 0.25s ease;
}

.syntax-highlighter code::-webkit-scrollbar-track {
  background: transparent;
}

.syntax-highlighter code::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 60px;
}

.syntax-highlighter code::-webkit-scrollbar:hover {
  background: rgba(255, 255, 255, 0.2);
}

.copy-btn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  color: #bebebe;
  overflow: hidden;
  padding: 8px 24px 8px 16px;
  border-radius: 0 24px 0 8px;
  background-color: #404040;
  transition: color 0.25s ease;
}

.copy-btn:hover {
  color: #fff;
}

.content-detail {
  width: 100%;
  height: 65vh;
  border-radius: 22px;
  overflow: hidden;
  user-select: all;
  border: 2px solid #404040;
  background-color: #0a0a0a;
}

.content-wrapper {
  width: 100%;
  height: 100%;
  font-size: 16px;
  text-align: left;
  padding: 30px;
  overflow: auto;
}

.content-wrapper::-webkit-scrollbar {
  width: 8px;
  height: 0;
  background: rgba(255, 255, 255, 0.1);
  transition: all 0.25s ease;
}

.content-wrapper::-webkit-scrollbar-track {
  background: transparent;
}

.content-wrapper::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 60px;
}

.content-wrapper::-webkit-scrollbar:hover {
  background: rgba(255, 255, 255, 0.2);
}

.content-detail h1 {
  font-size: 160%;
}

.content-detail h2 {
  font-size: 145%;
}

.content-detail h3 {
  font-size: 130%;
}

.content-detail h4 {
  font-size: 120%;
}

.content-detail h5 {
  font-size: 110%;
}

.content-detail h6 {
  font-size: 100%;
}

.content-detail p {
  font-size: 100%;
}

.content-detail h1,
.content-detail h2,
.content-detail h3,
.content-detail h4,
.content-detail h5,
.content-detail h6,
.content-detail p,
.content-detail img {
  text-align: left;
  margin-bottom: 20px;
}

.content-detail a {
  color: #629bf8;
  text-decoration: underline;
  text-underline-offset: 4px;
  transition: color 0.25s ease;
}

.content-detail a:hover {
  color: #8cb5f8;
}

.content-detail img {
  width: 100%;
  height: auto;
  pointer-events: none;
}

.input-css {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 12px;
  overflow: hidden;
  border: 2px solid #4b5563;
  background-color: #1F2937;
}

.input-css textarea {
  min-width: 100%;
  max-width: 100%;
  min-height: 200px;
  max-height: 300px;
  font-size: 14px;
  color: #fff;
  line-height: 1.5;
  padding: 12px 15px;
}

.input-css textarea::-webkit-scrollbar {
  width: 8px;
  height: 0;
  background: rgba(255, 255, 255, 0.1);
  transition: all 0.25s ease;
}

.input-css textarea::-webkit-scrollbar-track {
  background: transparent;
}

.input-css textarea::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 60px;
}

.input-css textarea::-webkit-scrollbar:hover {
  background: rgba(255, 255, 255, 0.2);
}

.tab-nav {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 30px;
  gap: 20px;
}

.tab-nav-item {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex: 1;
  font-size: 18px;
  color: #888;
  line-height: 1.4;
  padding: 10px 20px;
  gap: 10px;
  border-radius: 8px;
  background-color: #444;
  transition: color 0.25s ease, background-color 0.25s ease;
}

.tab-nav-item:hover {
  color: #fff;
  background-color: #0444ab;
}

.tab-nav-item.active {
  color: #fff;
  background-color: #3c82f4;
}

.tab-nav-item p {
  font-size: 18px;
  color: #888;
  font-weight: 500;
  transition: color 0.25s ease;
}

.tab-nav-item:hover p {
  color: #fff;
}

.tab-nav-item.active p {
  color: #fff;
  font-weight: 700;
}

.tab-content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-top: 20px;
}

.col-right .tab-content {
  margin-top: 0;
}

.tab-content-item {
  display: none;
  width: 100%;
}

.tab-content-item.active {
  display: block;
}

@media (max-width: 1919px) {
  body {
    font-size: 1.4583333333vw;
  }
  .header {
    height: 4.1666666667vw;
    padding: 0 2.0833333333vw;
    border-width: 0.15625vw;
  }
  .header-logo span {
    font-size: 1.5625vw;
  }
  .header-toggle {
    width: 5vw;
    padding-left: 0.1041666667vw;
  }
  .btn-nav-toggle {
    width: 2.2395833333vw;
    height: 1.5625vw;
  }
  .btn-nav-toggle span {
    height: 0.2083333333vw;
    width: 2.2395833333vw;
    border-radius: 2.6041666667vw;
  }
  .btn-nav-toggle span:nth-child(2) {
    top: 0.6770833333vw;
    width: 1.6666666667vw;
  }
  .btn-nav-toggle span:nth-child(3) {
    top: 0.6770833333vw;
    width: 1.6666666667vw;
  }
  .btn-nav-toggle span:nth-child(4) {
    top: 1.3541666667vw;
  }
  .btn-nav-toggle.active span:nth-child(1) {
    top: 0.6770833333vw;
  }
  .btn-nav-toggle.active span:nth-child(2) {
    width: 2.2395833333vw;
  }
  .btn-nav-toggle.active span:nth-child(3) {
    width: 2.2395833333vw;
  }
  .btn-nav-toggle.active span:nth-child(4) {
    top: 0.6770833333vw;
  }
  .nav-list {
    gap: 5.2083333333vw;
  }
  .nav-list li {
    font-size: 1.0416666667vw;
    padding: 0.78125vw 0;
  }
  .nav-list li:before {
    left: -2.6041666667vw;
    width: 0.1041666667vw;
    height: 1.3541666667vw;
  }
  .nav-list li a {
    text-underline-offset: 0.3125vw;
  }
  .main {
    padding-top: 4.1666666667vw;
  }
  .container {
    padding: 0 2.0833333333vw;
  }
  .col-left {
    width: 40.1041666667vw;
  }
  .col-right {
    width: 55.7291666667vw;
  }
  .space-left {
    padding-left: 5.2083333333vw;
    padding-right: 4.1666666667vw;
  }
  .space-right {
    padding-right: 5.2083333333vw;
  }
  h1 {
    font-size: 1.9791666667vw;
  }
  h1 span {
    font-size: 2.5vw;
  }
  .upload-file {
    margin-top: 1.0416666667vw;
  }
  .upload-file-btn {
    height: 9.375vw;
    padding: 0.625vw 1.0416666667vw;
    border-radius: 0.4166666667vw;
    border-width: 0.1041666667vw;
    box-shadow: inset 0 0.1041666667vw 0.1041666667vw rgba(0, 0, 0, 0.1);
  }
  .upload-file-icon {
    font-size: 1.875vw;
  }
  .upload-file-detail {
    margin-top: 0.5208333333vw;
  }
  .upload-file-label {
    font-size: 0.8333333333vw;
  }
  .submit-btn {
    min-height: 2.5520833333vw;
    font-size: 1.0416666667vw;
    gap: 0.78125vw;
    margin-top: 1.0416666667vw;
    padding: 0.9375vw 1.0416666667vw;
    border-radius: 0.4166666667vw;
  }
  .upload-desc {
    margin-top: 2.34375vw;
  }
  .upload-desc p {
    font-size: 0.8333333333vw;
    margin-bottom: 0.5208333333vw;
  }
  .upload-desc ul {
    margin-top: 0.5208333333vw;
    padding-left: 2.6041666667vw;
  }
  .upload-desc li {
    font-size: 0.8333333333vw;
  }
  .syntax-highlighter {
    font-size: 0.8333333333vw !important;
    border-radius: 1.1458333333vw !important;
    border: 0.1041666667vw solid #404040;
  }
  .syntax-highlighter code {
    font-size: 0.8333333333vw !important;
    padding: 1.0416666667vw 1.0416666667vw 1.0416666667vw 0.3125vw;
  }
  .react-syntax-highlighter-line-number {
    min-width: 3.6458333333vw !important;
    padding-right: 1.1458333333vw !important;
  }
  .syntax-highlighter code::-webkit-scrollbar {
    width: 0.4166666667vw;
  }
  .syntax-highlighter code::-webkit-scrollbar-thumb {
    border-radius: 3.125vw;
  }
  .copy-btn {
    gap: 0.4166666667vw;
    font-size: 0.8333333333vw;
    padding: 0.4166666667vw 1.25vw 0.4166666667vw 0.8333333333vw;
    border-radius: 0 1.25vw 0 0.4166666667vw;
  }
  .content-detail {
    border-radius: 1.1458333333vw;
    border-width: 0.1041666667vw;
  }
  .content-wrapper {
    font-size: 0.8333333333vw;
    padding: 1.5625vw;
  }
  .content-wrapper::-webkit-scrollbar {
    width: 0.4166666667vw;
  }
  .content-wrapper::-webkit-scrollbar-thumb {
    border-radius: 3.125vw;
  }
  .content-detail h1,
  .content-detail h2,
  .content-detail h3,
  .content-detail h4,
  .content-detail h5,
  .content-detail h6,
  .content-detail p,
  .content-detail img {
    margin-bottom: 1.0416666667vw;
  }
  .content-detail a {
    text-underline-offset: 0.2083333333vw;
  }
  .input-css {
    border-radius: 0.625vw;
    border: 0.1041666667vw solid #4b5563;
  }
  .input-css textarea {
    min-height: 10.4166666667vw;
    max-height: 15.625vw;
    font-size: 0.7291666667vw;
    padding: 0.625vw 0.78125vw;
  }
  .input-css textarea::-webkit-scrollbar {
    width: 0.4166666667vw;
  }
  .input-css textarea::-webkit-scrollbar-thumb {
    border-radius: 3.125vw;
  }
  .tab-nav {
    margin-top: 1.5625vw;
    gap: 1.0416666667vw;
  }
  .tab-nav-item {
    font-size: 0.9375vw;
    padding: 0.5208333333vw 1.0416666667vw;
    gap: 0.5208333333vw;
    border-radius: 0.4166666667vw;
  }
  .tab-nav-item p {
    font-size: 0.9375vw;
  }
  .tab-content {
    margin-top: 1.0416666667vw;
  }
}
@media (max-width: 768px) {
  body {
    font-size: 3.6458333333vw;
  }
  .header {
    height: auto;
    min-height: 13.0208333333vw;
    padding: 0 1.3020833333vw 0 3.90625vw;
    border-width: 0.390625vw;
    z-index: 99;
  }
  .header-logo {
    position: relative;
    z-index: 100;
  }
  .header-logo span {
    font-size: 4.4270833333vw;
  }
  .header-toggle {
    display: flex;
    width: 12.5vw;
    padding-left: 0.2604166667vw;
    z-index: 100;
  }
  .btn-nav-toggle {
    width: 5.5989583333vw;
    height: 3.90625vw;
  }
  .btn-nav-toggle span {
    height: 0.5208333333vw;
    width: 5.5989583333vw;
    border-radius: 6.5104166667vw;
  }
  .btn-nav-toggle span:nth-child(2) {
    top: 1.6927083333vw;
    width: 4.1666666667vw;
  }
  .btn-nav-toggle span:nth-child(3) {
    top: 1.6927083333vw;
    width: 4.1666666667vw;
  }
  .btn-nav-toggle span:nth-child(4) {
    top: 3.3854166667vw;
  }
  .btn-nav-toggle.active span:nth-child(1) {
    top: 1.6927083333vw;
  }
  .btn-nav-toggle.active span:nth-child(2) {
    width: 5.5989583333vw;
  }
  .btn-nav-toggle.active span:nth-child(3) {
    width: 5.5989583333vw;
  }
  .btn-nav-toggle.active span:nth-child(4) {
    top: 1.6927083333vw;
  }
  .header-nav {
    position: fixed;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100dvh;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    z-index: 99;
    background-color: #000;
    transition: all 0.6s ease;
  }
  .header-nav.active {
    left: 0;
    opacity: 1;
    visibility: visible;
  }
  .nav-list {
    flex-direction: column;
    gap: 13.0208333333vw;
  }
  .nav-list li {
    font-size: 4.4270833333vw;
    text-align: center;
    padding: 0;
  }
  .nav-list li:before {
    display: none;
  }
  .nav-list li a {
    text-underline-offset: 0.78125vw;
  }
  .nav-list li a:hover {
    opacity: 1;
    text-decoration: none;
  }
  .nav-list li a:active {
    opacity: 0.7;
    text-decoration: underline;
  }
  .main {
    height: 100dvh;
    padding-top: 13.0208333333vw;
  }
  .container {
    flex-direction: column;
    height: auto;
    padding: 3.2552083333vw 3.90625vw 5.2083333333vw;
  }
  .site-content {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .col-left,
  .col-right {
    width: 100%;
    height: auto;
  }
  .space-left {
    padding-left: 0;
    padding-right: 0;
  }
  .space-right {
    justify-content: flex-start;
    padding-right: 0;
  }
  h1 {
    font-size: 7.03125vw;
    text-align: center;
  }
  h1 span {
    font-size: 8.4635416667vw;
  }
  .upload-file {
    margin-top: 6.5104166667vw;
  }
  .upload-file-btn {
    height: 39.0625vw;
    padding: 3.90625vw;
    border-radius: 1.3020833333vw;
    border-width: 0.2604166667vw;
    box-shadow: inset 0 0.2604166667vw 0.2604166667vw rgba(0, 0, 0, 0.1);
  }
  .upload-file input[type=file]:hover + .upload-file-btn {
    border-color: #bdbdbd;
  }
  .upload-file-icon {
    font-size: 8.8541666667vw;
    color: #bdbdbd;
  }
  .upload-file-detail {
    margin-top: 2.6041666667vw;
  }
  .upload-file-label {
    font-size: 3.3854166667vw;
  }
  .upload-file input[type=file]:hover + .upload-file-btn .upload-file-icon,
  .upload-file input[type=file]:hover + .upload-file-btn .upload-file-label {
    color: #bdbdbd;
  }
  .upload-file input[type=file]:active + .upload-file-btn .upload-file-icon,
  .upload-file input[type=file]:active + .upload-file-btn .upload-file-label {
    color: #fff;
  }
  .submit-btn {
    min-height: 7.8125vw;
    font-size: 3.90625vw;
    gap: 1.953125vw;
    margin-top: 3.2552083333vw;
    padding: 3.2552083333vw 3.90625vw;
    border-radius: 1.0416666667vw;
  }
  .submit-btn:hover {
    background-color: #3c82f4;
  }
  .submit-btn:active {
    background-color: #0444ab;
  }
  .upload-desc {
    margin-top: 4.5572916667vw;
  }
  .upload-desc p {
    font-size: 2.6041666667vw;
    margin-bottom: 1.3020833333vw;
  }
  .upload-desc ul {
    columns: 2;
    margin-top: 1.3020833333vw;
    padding-left: 6.5104166667vw;
  }
  .upload-desc li {
    font-size: 2.6041666667vw;
  }
  .code-content {
    margin-top: 6.5104166667vw;
  }
  .syntax-highlighter {
    height: 76vw;
    font-size: 2.0833333333vw !important;
    border-radius: 2.8645833333vw !important;
    border-width: 0.5208333333vw;
  }
  .syntax-highlighter code {
    font-size: 2.0833333333vw !important;
    padding: 2.6041666667vw 2.6041666667vw 2.6041666667vw 0.78125vw;
  }
  .react-syntax-highlighter-line-number {
    min-width: 9.1145833333vw !important;
    padding-right: 2.8645833333vw !important;
  }
  .syntax-highlighter code::-webkit-scrollbar {
    width: 1.3020833333vw;
  }
  .syntax-highlighter code::-webkit-scrollbar-thumb {
    border-radius: 7.8125vw;
  }
  .syntax-highlighter code::-webkit-scrollbar:hover {
    background: rgba(255, 255, 255, 0.1);
  }
  .syntax-highlighter code::-webkit-scrollbar:active {
    background: rgba(255, 255, 255, 0.2);
  }
  .copy-btn {
    gap: 1.0416666667vw;
    font-size: 2.8645833333vw;
    padding: 1.5625vw 3.6458333333vw 1.5625vw 2.6041666667vw;
    border-radius: 0 3.125vw 0 1.3020833333vw;
  }
  .copy-btn:hover {
    color: #bebebe;
  }
  .copy-btn:active {
    color: #fff;
  }
  .content-detail {
    height: 80vw;
    border-radius: 2.8645833333vw;
    border-width: 0.5208333333vw;
  }
  .content-wrapper {
    font-size: 2.0833333333vw;
    padding: 3.90625vw;
  }
  .content-wrapper::-webkit-scrollbar {
    width: 1.3020833333vw;
  }
  .content-wrapper::-webkit-scrollbar-thumb {
    border-radius: 7.8125vw;
  }
  .content-wrapper::-webkit-scrollbar:hover {
    background: rgba(255, 255, 255, 0.1);
  }
  .content-wrapper::-webkit-scrollbar:active {
    background: rgba(255, 255, 255, 0.2);
  }
  .content-detail h1,
  .content-detail h2,
  .content-detail h3,
  .content-detail h4,
  .content-detail h5,
  .content-detail h6,
  .content-detail p,
  .content-detail img {
    margin-bottom: 2.6041666667vw;
  }
  .content-detail a {
    text-underline-offset: 0.5208333333vw;
  }
  .content-detail a:hover {
    color: #629bf8;
  }
  .content-detail a:active {
    color: #8cb5f8;
  }
  .input-css {
    border-radius: 1.5625vw;
    border-width: 0.2604166667vw;
  }
  .input-css textarea {
    min-height: 32.5520833333vw;
    max-height: 65.1041666667vw;
    font-size: 2.34375vw;
    padding: 1.5625vw 1.953125vw;
  }
  .input-css textarea::-webkit-scrollbar {
    width: 1.3020833333vw;
  }
  .input-css textarea::-webkit-scrollbar-thumb {
    border-radius: 7.8125vw;
  }
  .input-css textarea::-webkit-scrollbar:hover {
    background: rgba(255, 255, 255, 0.1);
  }
  .input-css textarea::-webkit-scrollbar:active {
    background: rgba(255, 255, 255, 0.2);
  }
  .tab-nav {
    margin-top: 5.2083333333vw;
    gap: 2.6041666667vw;
  }
  .tab-nav-item {
    font-size: 3.125vw;
    padding: 1.8229166667vw 2.6041666667vw;
    gap: 1.3020833333vw;
    border-radius: 1.0416666667vw;
  }
  .tab-nav-item:hover {
    color: #888;
    background-color: #444;
  }
  .tab-nav-item.active {
    color: #fff;
    background-color: #3c82f4;
  }
  .tab-nav-item p {
    font-size: 3.125vw;
  }
  .tab-nav-item:hover p {
    color: #888;
  }
  .tab-nav-item.active p {
    color: #fff;
  }
  .tab-content {
    margin-top: 3.2552083333vw;
  }
}

